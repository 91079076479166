import { type NextBestAction } from '@post-office/confluence-next-best-action';
import { ConfluenceNbaBannerMessage } from '@post-office/placements--growth/components/confluence-side-nav';
import { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

export const ConfluenceCreateASpaceMessage = ({
	onSideNavClick,
	handleOnDismissed,
}: {
	onSideNavClick: (nextBestActionType: NextBestAction) => void;
	handleOnDismissed: () => void;
}) => {
	const intl = useIntl();
	const headerText = intl.formatMessage({
		id: 'post-office.confluence-side-nav.next-best-action.craete-space-header',
		defaultMessage: "Centralize your team's projects, and docs.",
		description: 'Header text for confluence side nav next best action banner',
	});

	const ctaMessage = intl.formatMessage({
		id: 'post-office.confluence-side-nav.next-best-action.create-space-cta-message',
		defaultMessage: 'Create space',
		description: 'Call to action text for confluence side nav next best action banner',
	});

	const onMessageClick = useCallback(() => {
		onSideNavClick('CREATE_SPACE');
	}, [onSideNavClick]);

	const NBA = {
		icon: (
			<div>
				<svg
					width="40"
					height="40"
					viewBox="0 0 40 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M33.2748 18.1208H12.979V28.0375H33.2748V18.1208Z" fill="#BF63F3" />
					<path d="M26.9667 11.9624H6.6709V21.8791H26.9667V11.9624Z" fill="#FCA700" />
					<path d="M26.9665 18.1208H12.979V21.8792H26.9665V18.1208Z" fill="#AE2A19" />
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M32.0125 21.7042L27.6416 26.075L25.3833 23.7542L26.3583 22.8084L27.6583 24.1417L31.0541 20.7458L32.0166 21.7083L32.0125 21.7042Z"
						fill="white"
					/>
				</svg>
			</div>
		),
		header: headerText,
		ctaMessage: ctaMessage,
	};
	return (
		<ConfluenceNbaBannerMessage
			icon={NBA.icon}
			header={NBA.header}
			ctaMessage={NBA.ctaMessage}
			onMessageClick={onMessageClick}
			handleOnDismissed={handleOnDismissed}
		/>
	);
};
