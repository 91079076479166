import React, { useCallback, useEffect } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import AlignTextLeftIcon from '@atlaskit/icon/core/align-text-left';
import { Box, xcss, Stack } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import CrossCircle from '@atlaskit/icon/core/cross-circle';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system/side-nav/menu-section';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { useStarredData } from '@confluence/space-utils/entry-points/useSpacesData';
import { SPACE_OVERVIEW, SPACE_DIRECTORY } from '@confluence/named-routes';
import { SpaceIcon } from '@confluence/icons/entry-points/SpaceIcon';
import { useSessionData } from '@confluence/session-data';
import { withFlags } from '@confluence/flags';

const StarredSpacesListComponent = ({ flags }) => {
	const { isLicensed } = useSessionData();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { starredSpaces, error, loading } = useStarredData(isLicensed);

	const onViewAllClick = useCallback(() => {
		fireStarredSpacesListItemClickedAnalytics(createAnalyticsEvent, '4', {
			componentType: 'fixedItem',
			itemId: 'viewAllSpaces',
			section: 'starredSpaces',
		});
	}, [createAnalyticsEvent]);

	const onStarredSpaceItemClick = useCallback(
		(spaceId: string | null) => {
			fireStarredSpacesListItemClickedAnalytics(createAnalyticsEvent, '4', {
				componentType: 'customItem',
				itemType: 'space',
				itemId: spaceId,
				section: 'starredSpaces',
			});
		},
		[createAnalyticsEvent],
	);

	useEffect(() => {
		if (!loading && error) {
			flags.showErrorFlag({
				icon: <CrossCircle label="" color={token('color.icon.danger')} />,
				title: <FormattedMessage {...i18n.errorFlagTitle} />,
				description: <FormattedMessage {...i18n.errorFlagDescription} />,
				isAutoDismiss: true,
			});
		}
	}, [loading, error, flags]);

	return (
		<MenuSection>
			<MenuSectionHeading>
				<FormattedMessage {...i18n.title} />
			</MenuSectionHeading>

			{loading && <LoadingSkeletons />}

			{!loading && starredSpaces.length === 0 && (
				<Box padding="space.100" xcss={emptySpacesTextStyles}>
					{intl.formatMessage(i18n.empty)}
				</Box>
			)}

			{!loading &&
				starredSpaces &&
				starredSpaces.map((space) => {
					const spaceAlias = space.alias || space.key;
					return (
						<MenuLinkItem
							key={space.id}
							href={SPACE_OVERVIEW.toUrl({ spaceKey: spaceAlias })}
							elemBefore={<SpaceIcon size="xsmall" src={space.icon?.path} />}
							onClick={() => onStarredSpaceItemClick(space.id)}
						>
							{space.name}
						</MenuLinkItem>
					);
				})}

			{!loading && (
				<MenuLinkItem
					href={SPACE_DIRECTORY.toUrl()}
					elemBefore={<AlignTextLeftIcon label="" color="currentColor" />}
					onClick={onViewAllClick}
				>
					<FormattedMessage {...i18n.viewAll} />
				</MenuLinkItem>
			)}
		</MenuSection>
	);
};

export const StarredSpacesList = withFlags(StarredSpacesListComponent);

const fireStarredSpacesListItemClickedAnalytics = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	navVersion: string,
	options: {
		componentType?: string;
		itemId?: string | null;
		itemType?: string;
		section?: string;
	} = {},
) => {
	const { componentType, itemId, itemType, section } = options;

	createAnalyticsEvent({
		type: 'sendUIEvent',
		data: {
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: 'spacesNavigationMenuItem',
			source: 'globalNavigation',
			attributes: {
				navigationLayer: 'global',
				navigationContainer: 'sidebar',
				navVersion,
				componentType,
				itemId,
				itemType,
				section,
			},
		},
	}).fire();
};

const LoadingSkeletons = () => (
	<Box paddingBlockStart="space.075" paddingInlineStart="space.075" testId="loading">
		<Stack space="space.150">
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
		</Stack>
	</Box>
);

const emptySpacesTextStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.UNSAFE_small',
});

const i18n = defineMessages({
	title: {
		id: 'side-navigation.starred-spaces-list.title',
		defaultMessage: 'Starred spaces',
		description: 'Title for the starred spaces list',
	},
	viewAll: {
		id: 'side-navigation.starred-spaces-list.view-all',
		defaultMessage: 'View all spaces',
		description: 'Text for link to space directory',
	},
	empty: {
		id: 'side-navigation.starred-spaces-list.empty-message',
		defaultMessage: 'Spaces you star will appear here',
		description: 'Displayed when there are no spaces to show in starred spaces list',
	},
	errorFlagTitle: {
		id: 'side-navigation.starred-spaces-list.error-flag-title',
		defaultMessage: 'We couldn’t load starred spaces',
		description: 'Title of error flag shown when starred spaces fail to load',
	},
	errorFlagDescription: {
		id: 'side-navigation.starred-spaces-list.error-flag-description',
		defaultMessage:
			'We are unable to show your spaces at this time. Refresh the page or try again.',
		description: 'Description of error flag shown when starred spaces fail to load',
	},
});
