import React, { type VFC } from 'react';

import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import { ErrorBoundary, Attribution } from '@confluence/error-boundary';

import { TopNavigation } from '../src/TopNavigation';

export const TopNavigationBase: VFC<{ defaultCollapsed: boolean }> = ({ defaultCollapsed }) => {
	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'TopNavigation',
			}}
		>
			<PageSegmentLoadStart metric={APP_NAVIGATION_METRIC} />
			<TopNavigation defaultCollapsed={defaultCollapsed} />
		</ErrorBoundary>
	);
};
