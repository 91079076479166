import React, { type ComponentProps } from 'react';

import { LoadablePaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { GLOBAL_NAVIGATION_METRIC } from './perf.config';

const GlobalNavigationLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalNavigation" */ './GlobalNavigationComponent'
			)
		).GlobalNavigationComponent,
});

export const GlobalNavigation = (props: GlobalNavigationProps) => (
	<>
		<PageSegmentLoadStart metric={GLOBAL_NAVIGATION_METRIC} />
		<GlobalNavigationLoader {...props} />
	</>
);

type GlobalNavigationProps = ComponentProps<typeof GlobalNavigationLoader>;
