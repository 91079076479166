import React, { forwardRef, useCallback } from 'react';

import Avatar from '@atlaskit/avatar';

import { UserAction, type UserActionProps } from './user-action';

interface ProfileProps extends Omit<UserActionProps, 'icon'> {
	/**
	 * The URL of the image to display in the avatar.
	 */
	src?: string;
}

/**
 * __Profile__
 *
 * The Profile button for the top navigation.
 */
export const Profile = forwardRef<HTMLButtonElement, ProfileProps>(({ src, ...props }, ref) => {
	const ProfileAvatar = useCallback(
		() => <Avatar appearance="circle" src={src} size="small" />,
		[src],
	);

	return <UserAction {...props} ref={ref} icon={ProfileAvatar} />;
});
