import React, { useMemo } from 'react';
import { css } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ConfluenceIcon, ConfluenceLogo } from '@atlaskit/logo';
import {
	ConfluenceIcon as NewConfluenceIcon,
	ConfluenceLogo as NewConfluenceLogo,
} from '@atlaskit/temp-nav-app-icons/confluence';

import { NavLogo } from '@atlassian/navigation-system/top-nav';

import { useRouteDataRef } from '@confluence/route-manager';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { WIKI } from '@confluence/named-routes';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';

import type {
	TopNavigationQuery_siteConfiguration as SiteConfiguration,
	TopNavigationQuery_siteSettings as SiteSettings,
	TopNavigationQuery_siteDescription as SiteDescription,
} from './__types__/TopNavigationQuery';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireLogoClickedAnalytics,
);

const customLogo = css({
	width: '28px',
	height: '28px',
});

type SiteLogoButtonProps = {
	siteConfiguration: SiteConfiguration | undefined;
	siteSettings: SiteSettings | undefined | null;
	siteDescription: SiteDescription | undefined | null;
};

export const SiteLogoButton = ({
	siteConfiguration,
	siteSettings,
	siteDescription,
}: SiteLogoButtonProps) => {
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const href = WIKI.toUrl();
	const onClick = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef.current?.routeName);
	const showSiteTitle = siteSettings?.showApplicationTitle;
	const siteTitle = showSiteTitle ? siteSettings?.siteTitle : undefined;
	const isNav4Enabled = useIsNav4Enabled();

	const CustomNavLogo = useMemo(() => {
		if (siteConfiguration?.customSiteLogo && siteDescription?.logoUrl) {
			if (isNav4Enabled) {
				return () => <img alt="" src={siteDescription.logoUrl as string} />;
			}
			return () => <img alt="" src={siteDescription.logoUrl as string} css={customLogo} />;
		}

		return null;
	}, [isNav4Enabled, siteConfiguration?.customSiteLogo, siteDescription?.logoUrl]);

	const commonProps = {
		href,
		onClick,
	};

	if (CustomNavLogo) {
		return (
			<NavLogo
				label={siteTitle ?? 'Confluence'}
				logo={CustomNavLogo}
				icon={CustomNavLogo}
				{...commonProps}
			/>
		);
	}

	if (fg('platform-team25-app-icon-tiles')) {
		return (
			<NavLogo
				label="Confluence"
				icon={() => (
					// tokens were not used because @atlaskit/temp-nav-app-icons/confluence icon/logo
					// have their own padding and 5px was the only way to make the icons align.
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
					<span style={{ marginTop: '5px' }}>
						<NewConfluenceIcon size="24" />
					</span>
				)}
				logo={() => <NewConfluenceLogo />}
				{...commonProps}
			/>
		);
	}

	return (
		<NavLogo label="Confluence" logo={ConfluenceLogo} icon={ConfluenceIcon} {...commonProps} />
	);
};
