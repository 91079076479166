import React, { useRef, useCallback, useMemo } from 'react';

import { SideNavContent } from '@atlassian/navigation-system/layout/side-nav';

import { SideNavigationContext } from './SideNavigationContext';

export const SideNavigationContent = ({ children }) => {
	const sideNavRef = useRef<HTMLDivElement>(null);

	const handleScrollToTop = useCallback(() => {
		if (sideNavRef.current) {
			sideNavRef.current.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}, [sideNavRef]);

	const contextValue = useMemo(
		() => ({ sideNavRef, handleScrollToTop }),
		[sideNavRef, handleScrollToTop],
	);

	return (
		<SideNavigationContext.Provider value={contextValue}>
			<SideNavContent ref={sideNavRef}>{children}</SideNavContent>
		</SideNavigationContext.Provider>
	);
};
