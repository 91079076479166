import {
	type AnyArityOneFunction,
	type ArityOneParameter,
} from '@post-office/shared-contracts/pipeline/contraints';

import { PlacementSystemError } from '../../error';
import { type PlacementLocation, type RequestContext, type SyncRequestContext } from '../../types';

const HISTOGRAM_LATENCY_INTERVAL_BUCKETS = [25, 50, 100, 150, 200, 250, 500, 1000];

const createTimedStage =
	(keys: Array<keyof PlacementLocation>) =>
	<T extends (params: { request: SyncRequestContext }) => unknown>(stage: T): T =>
		(async (params: ArityOneParameter<T>) => {
			const result = stage(params);

			if (!(result instanceof Promise)) {
				return result as ReturnType<T>;
			}

			const timerName = buildTimerName(keys)(params);

			const { stop } = params.request.metrics
				.histogram(timerName, HISTOGRAM_LATENCY_INTERVAL_BUCKETS)
				.measure();

			try {
				return (await result) as ReturnType<T>;
			} finally {
				stop();
			}
		}) as T;

const createTimedSystemStage =
	(keys: Array<keyof PlacementLocation>) =>
	<T extends AnyArityOneFunction>(fn: (context: { request: SyncRequestContext }) => T) =>
	(contexts: { request: RequestContext }): T => {
		return (async (params: ArityOneParameter<T>) => {
			const result = fn(contexts)(params);

			if (!(result instanceof Promise)) {
				return result as ReturnType<T>;
			}

			const timerName = buildTimerName(keys)(contexts);

			const { stop } = contexts.request.metrics
				.histogram(timerName, HISTOGRAM_LATENCY_INTERVAL_BUCKETS)
				.measure();

			try {
				return (await result) as ReturnType<T>;
			} finally {
				stop();
			}
		}) as T;
	};

const buildTimerName =
	(keys: Array<keyof PlacementLocation>) => (params: { request: SyncRequestContext }) => {
		const placementLocation = params.request.location.getCurrent();

		const stringValues = keys.map((e) => placementLocation?.[e]);

		stringValues.forEach((stringValue) => {
			if (typeof stringValue === 'undefined') {
				throw new PlacementSystemError({
					...placementLocation,
					message: 'required location values not set while trying to build timer name.',
				});
			}
		});

		const standardisedValues = ['post-office', 'placement-v2', ...stringValues, 'timer'];

		return standardisedValues.map(toKebabCase).join('.');
	};

const toKebabCase = (input: string): string =>
	input.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

export const placementMessageTimedStage = createTimedStage([
	'placementId',
	'placementLevel',
	'messageTemplateId',
	'stageName',
]);

export const placementTimedStage = createTimedStage(['placementId', 'placementLevel', 'stageName']);

export const placementRuntimeTimedStage = createTimedSystemStage(['placementId', 'placementLevel']);
