import { useQuery } from '@apollo/react-hooks';

import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { getTrialDaysLeft } from '@confluence/trial-components/entry-points/getTrialDaysLeft';

import { LicenseDetailsQuery } from './LicenceDetailsQuery.graphql';
import type { LicenseDetailsQuery as LicenseDetailsQueryType } from './__types__/LicenseDetailsQuery';

export function usePersistentInviteButtonEligible() {
	const { edition, isLicensed, isLoggedIn } = useSessionData();

	const isFreeEdition = edition === ConfluenceEdition.FREE;

	const {
		data: licenseDetailsData,
		loading: licenseDetailsLoading,
		error: licenseDetailsDataError,
	} = useQuery<LicenseDetailsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LicenseDetailsQuery,
		{
			skip: isFreeEdition || !isLoggedIn || !isLicensed,
		},
	);

	const trialDaysLeft = getTrialDaysLeft(licenseDetailsData?.license?.trialEndDate);
	const isInTrial = trialDaysLeft ? trialDaysLeft > 0 : false;

	return {
		loading: licenseDetailsLoading,
		error: licenseDetailsDataError,
		isEligible: isInTrial || isFreeEdition,
	};
}
