import React, { useState, useCallback } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';

import { LoadableLazy } from '@confluence/loadable';

import type { TopNavigationQuery_user as User } from './__types__/TopNavigationQuery';

const AccountMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AccountMenu" */ '@confluence/nav-menus/entry-points/AccountMenu'
			)
		).AccountMenu,
});

type AccountButtonProps = {
	user: User;
};

export const AccountButton = ({ user }: AccountButtonProps) => {
	const [activeDialog, setActiveDialog] = useState(null);

	const closeDialog = useCallback(() => {
		setActiveDialog(null);
	}, [setActiveDialog]);

	const openDialog = useCallback(
		(dialog: any) => {
			setActiveDialog(dialog(closeDialog));
		},
		[setActiveDialog, closeDialog],
	);

	return (
		<>
			<AccountMenuLoader user={user} dialogFn={openDialog} />
			<ModalTransition>{activeDialog}</ModalTransition>
		</>
	);
};
