import { xcss } from '@atlaskit/primitives';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { type FunctionComponent, type ReactNode } from 'react';

import { NextBestActionPressable } from '../next-best-action-button';
import { useActionMessageClicked } from '../next-best-action-button/use-action-message-clicked';

const nextBestActionButtonStyles = xcss({
	display: 'flex',
	width: 'fit-content',
	paddingLeft: '0',
	color: 'color.link',
	backgroundColor: 'color.background.brand.subtlest.hovered',
	':hover': {
		textDecoration: 'underline',
	},
});

const NEXT_BEST_ACTION_CTA_LINK: ClickedElementAttributes = {
	clickIdentifier: 'next-best-action-message',
	clickedElement: 'link',
};

export const NextBestAction: FunctionComponent<{
	ctaMessage: ReactNode;
	onCTAClick: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ ctaMessage, onCTAClick }) => {
	const { handleClick } = useActionMessageClicked(onCTAClick, NEXT_BEST_ACTION_CTA_LINK);

	return (
		<NextBestActionPressable
			ctaText={ctaMessage}
			onCTAClick={handleClick}
			pressableStyle={nextBestActionButtonStyles}
		/>
	);
};
