import { differenceInDays } from 'date-fns';

import type { TrialDetailsQuery as TrialDetailsQueryType } from './__types__/TrialDetailsQuery';

export const getTrialDaysLeftFromQuery = (queryData: TrialDetailsQueryType | undefined) => {
	const trialEndDate = queryData?.license?.trialEndDate;
	return getTrialDaysLeft(trialEndDate);
};

export const getTrialDaysLeft = (trialEndDate: string | null | undefined) => {
	if (!trialEndDate) {
		return null;
	}

	// +1 day to round up for fractional days
	return differenceInDays(new Date(trialEndDate), new Date()) + 1;
};
