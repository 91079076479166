import type { NextBestAction } from '@post-office/confluence-next-best-action';
import {
	type BackendMessageBody,
	type DefaultMessagesResponse,
	createPlacement,
} from '@post-office/placement-common-v2';
import type { SerializableRecord } from '@post-office/serializable-record';
import { usePlacementApi } from '@post-office/use-placement-api';
import { useEffect, useState } from 'react';

const { createFrontend, createBackend } = createPlacement({
	placementId: 'confluence-side-nav',
	options: {
		choreographer: {
			isDefaultEnabled: false,
		},
	},
});

export const PlacementBackend = createBackend();

// Props passed from Product to Placement
type PlacementFrontendProps = {
	preloadedInitialData: DefaultMessagesResponse | undefined;
	onSideNavClick: (nextBestActionType: NextBestAction) => void;
};

export const PlacementFrontend = createFrontend(
	(Messages) =>
		({ preloadedInitialData, onSideNavClick }: PlacementFrontendProps) => {
			const { data, error, isLoading } = usePlacementApi<DefaultMessagesResponse>('/');

			const [{ message, status }, setMessageState] = useState<{
				message: BackendMessageBody<SerializableRecord> | undefined;
				status: 'loading' | 'done';
			}>(() => {
				if (preloadedInitialData) {
					return preloadedInitialData?.messages?.length > 0
						? {
								message: preloadedInitialData?.messages[0],
								status: 'done',
							}
						: { message: undefined, status: 'done' };
				}

				return { message: undefined, status: 'loading' };
			});

			const handleOnDismissed = () => {
				setMessageState({ message: undefined, status: 'done' });
			};

			const isDone = status === 'done';
			useEffect(() => {
				if (!isLoading && !isDone) {
					const newMessage = (data?.messages || [])[0];

					setMessageState({
						message: newMessage,
						status: 'done',
					});
				}
			}, [isLoading, isDone, data]);

			if (error) {
				return null;
			}

			if (status === 'loading') {
				return null;
			}

			return (
				<div
					style={{
						marginBottom: '10px',
						maxWidth: '296px',
					}}
				>
					{message && (
						<Messages.render
							{...message}
							onSideNavClick={onSideNavClick}
							handleOnDismissed={handleOnDismissed}
						/>
					)}
				</div>
			);
		},
);
