import { B50 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { type AdControls } from '@post-office/components--ad-controls';
import { useMessageContext } from '@post-office/message-context';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { type ComponentProps, type FunctionComponent, type ReactNode } from 'react';

import { Actions } from './components/actions';
import { NextBestAction } from './components/cta';
import { useActionMessageClicked } from './components/next-best-action-button/use-action-message-clicked';
import { type Nba } from './types';

export type Props = {
	onMessageClick: () => void;
	whyAmISeeingThisModalContent?: ComponentProps<typeof AdControls>['whyAmISeeingThisModalContent'];
	handleOnDismissed: () => void;
} & Nba;

const confluenceNbaMessageStyles = css({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	borderRadius: token('border.radius.200', '8px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	'@media (max-width: 248px)': {
		display: 'none',
	},
	minWidth: '230px',
	maxWidth: '272px',
	minHeight: '72px',
	padding: `${token('space.150', '12px')} ${token('space.150', '12px')} ${token('space.150', '12px')}${token('space.075', '6px')}`,
	'&:hover': {
		cursor: 'pointer',
		outline: `1px solid ${token('color.background.accent.blue.subtle.hovered')}`,
	},
});

const iconContainerStyles = css({
	display: 'flex',
	position: 'absolute',
	top: '12px',
	width: '40px',
	height: '40px',
});

const bodyContentStyles = css({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '40px',
	paddingLeft: token('space.100', '8px'),
	width: '100%',
	minWidth: '154px',
	maxWidth: '196px',
});

const headerStyles = css({
	width: '100%',
	font: token('font.heading.xsmall', fontFallback.heading.xsmall),
	paddingBottom: token('space.100', '8px'),
});

const BodyContent: FunctionComponent<{
	header: ReactNode;
	ctaMessage: ReactNode;
	onMessageClick: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ header, ctaMessage, onMessageClick }) => {
	return (
		<div css={bodyContentStyles}>
			<div role="presentation" css={headerStyles}>
				{header}
			</div>
			<NextBestAction ctaMessage={ctaMessage} onCTAClick={onMessageClick} />
		</div>
	);
};

const NEXT_BEST_ACTION_MESSAGE_CLICKED_ELEMENT: ClickedElementAttributes = {
	clickIdentifier: 'next-best-action-message',
	clickedElement: 'link',
};

export const ConfluenceNbaBannerMessage = ({
	icon,
	header,
	ctaMessage,
	onMessageClick,
	whyAmISeeingThisModalContent,
	handleOnDismissed,
}: Props) => {
	const { messageInstanceId } = useMessageContext();
	if (!messageInstanceId) {
		throw new Error('messageInstanceId is required');
	}
	const { handleClick } = useActionMessageClicked(
		onMessageClick,
		NEXT_BEST_ACTION_MESSAGE_CLICKED_ELEMENT,
	);

	const handleMessageClick = (e: React.MouseEvent<HTMLElement>) => {
		const currentTarget = e.currentTarget as HTMLElement;

		let target = e.target as HTMLElement;
		while (target.parentNode) {
			if (
				target.tagName.toLowerCase() === 'button' ||
				target.tagName.toLowerCase() === 'a' ||
				target.tagName.toLowerCase() === 'html' // this is for the modal & its transparent background
			) {
				// Don't open action since these child elements should've already handled the click
				return;
			}

			// Open action if the click is on the banner message itself
			if (target === currentTarget) {
				handleClick(e);
				return;
			}

			// Keep traversing up the DOM tree
			target = target.parentNode as HTMLElement;
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			data-testid="confluence-next-best-action-message"
			css={confluenceNbaMessageStyles}
			onClick={handleMessageClick}
		>
			<div css={iconContainerStyles}>{icon}</div>
			<BodyContent header={header} ctaMessage={ctaMessage} onMessageClick={onMessageClick} />
			<Actions
				whyAmISeeingThisModalContent={whyAmISeeingThisModalContent}
				handleOnDismissed={handleOnDismissed}
			/>
		</div>
	);
};
