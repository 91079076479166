import gql from 'graphql-tag';

export const LicenseDetailsQuery = gql`
	query LicenseDetailsQuery {
		license {
			licenseConsumingUserCount
			trialEndDate
		}
	}
`;
