import { defaultTransformMetadata } from '../../../stages/placement-message/backend/transform-metadata/default';

export const defaultMessageBackend = () => ({
	transformMetadata: {
		validate: noValidation,
		stage: defaultTransformMetadata,
	},
});

export const noValidation = (input: unknown) => input as never;
