import React from 'react';

import BitbucketBranchesIcon from '@atlaskit/icon/core/branch';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { LoadableAfterPaint } from '@confluence/loadable';
import { getOverrides, removeAllOverrides } from '@confluence/session-data';
import { isBranchDeployFn } from '@confluence/atlassian-internal-help-items';
import { fg } from '@confluence/feature-gating';

const BranchDeployLogoLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-BranchDeployLogo" */ './BranchDeployLogo'))
			.BranchDeployLogo,
	loading: () => <BitbucketBranchesIcon label="Loading..." />,
});

const FeatureFlagLogoLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-FeatureFlagLogo" */ './FeatureFlagLogo'))
			.FeatureFlagLogo,
});

export const DeveloperButtons = ({ isAtlassianStaff = false }) => {
	const isBranchDeploy = isBranchDeployFn();
	const featureFlagOverrides = getOverrides();
	const featureGateOverrides = FeatureGates.getOverrides();
	const hasOverrides =
		Object.keys(featureFlagOverrides).length > 0 ||
		Object.keys(featureGateOverrides.gates).length > 0 ||
		Object.keys(featureGateOverrides.configs).length > 0;

	return (
		<>
			{isBranchDeploy && <BranchDeployLogoLoader />}
			{(!fg('confluence_dev_tools_from_flag_icon') || isAtlassianStaff) && hasOverrides && (
				<FeatureFlagLogoLoader
					featureFlagOverrides={featureFlagOverrides}
					featureGateOverrides={featureGateOverrides}
					onReset={() => {
						removeAllOverrides();
						FeatureGates.clearAllOverrides();
						window.location.reload();
					}}
				/>
			)}
		</>
	);
};
