import { useStableObject } from '@atlassian/post-office-context';
import { MessageRendererProvider } from '@post-office/placement-contexts';

import { createPlacementComponentV2 } from './create-placement-component';
import { type RuntimeParams } from './create-placement-component/types';
import { defaultPlacementMiddleware } from './default-placement-middleware';
import {
	PlacementMessageError,
	PlacementSystemError,
	createPlacementMessageError,
	handlePlacementError,
	handlePlacementMessageError,
	handlePlacementMiddlewareError,
	handlePlacementRuntimeError,
} from './error';
import type {
	AnyBackendMessageStages,
	AnyBackendPlacementStage,
	AnyBackendPlacementStageInput,
	AnyBackendPlacementStages,
	AnyFrontendStages,
	AnyPlacementDefinition,
	AnyPlacementMessageStagesConfig,
	AnyProps,
	AnyValidatedBackendMessageStage,
	AnyValidatedBackendMessageStages,
	AnyValidatedStages,
	CreateBackendPlacementMessageExecuteConfig,
	CreateBackendPlacementMessageRunner,
	CreateFrontendPlacementMessageExecuteConfig,
	CreateFrontendPlacementMessageRunner,
	DefaultBackendMessageStages,
	DefaultFrontendStages,
	DefaultPlacementDefinition,
	MessagesRuntime,
	NoProps,
	PlacementBackend,
	PlacementConfig,
	PlacementKoaContext,
	PlacementLocation,
	PlacementMessage,
	PlacementMessageBackendFactory,
	PlacementMessageFrontendFactory,
	PlacementPostOfficeContext,
	PlacementRenderer,
	PlacementRestMiddleware,
} from './types';
import { noValidation } from './util/message-helpers';
import {
	placementMessageTimedStage,
	placementRuntimeTimedStage,
	placementTimedStage,
} from './util/observability';
import { defaultBackendPlacementStages } from '../stages/placement/backend/default';
import { defaultBackendPlacementMessageStages } from '../stages/placement-message/backend/default';

export const createPlacement = <T extends AnyPlacementDefinition = DefaultPlacementDefinition>(
	placementConfig: PlacementConfig,
) => {
	return {
		createFrontend: createFrontend<T['frontend']>(placementConfig),
		createBackend: createBackend<T['backend']>(placementConfig),
	};
};

/*-- Frontend --*/

const createFrontend =
	<T extends AnyFrontendStages = DefaultFrontendStages>(placementConfig: PlacementConfig) =>
	<U extends AnyProps = NoProps>(
		placementRenderer: PlacementRenderer<T, U>,
	): PlacementMessageFrontendFactory<T, U> => ({
		createMessageFrontend: (stage) => (config) => [config.messageTemplateId, stage],
		createPlacementFrontendComponent: (config) => {
			const messageTemplateRouter = createFrontendPlacementMessageTemplateRouter({
				...placementConfig,
				...config,
			});

			return createPlacementComponentV2(
				placementConfig.placementId,
				placementRenderer(messageTemplateRouter),
				placementConfig.options,
			);
		},
	});

const createFrontendPlacementMessageRunner: CreateFrontendPlacementMessageRunner =
	({ messageTemplates, ...config }) =>
	({ messageTemplateId, messageInstanceId, messageCategory, children, ...props }) => {
		const renderableStage = messageTemplates?.[messageTemplateId];

		const placementLocation: PlacementLocation = {
			messageTemplateId,
			messageInstanceId,
			placementLevel: 'PlacementMessage',
			name: config?.stageName,
			placementId: config.placementId,
		};

		try {
			if (!renderableStage) {
				// eslint-disable-next-line no-console
				console.warn(new PlacementMessageError({ ...placementLocation, ...config }));

				return null;
			}

			renderableStage.validate({ messageTemplateId, children, ...props }); // Will throw error if type validation fails
			const { component: Component } = renderableStage;
			const componentPropsStable = useStableObject(props);

			return (
				<MessageRendererProvider
					{...componentPropsStable}
					messageTemplateId={messageTemplateId}
					messageInstanceId={messageInstanceId}
					messageCategory={messageCategory}
					isUFOEnabled={false} // TODO enable UFO for placement v2
					componentProps={componentPropsStable}
				>
					<Component {...props}>{children}</Component>
				</MessageRendererProvider>
			);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(createPlacementMessageError(error));

			return null;
		}
	};

const createFrontendPlacementMessageTemplateRouter = <T extends AnyFrontendStages>(
	config: CreateFrontendPlacementMessageExecuteConfig<T>,
) => {
	const messageTemplateLookup = createMessageTemplateByStageLookup(config);

	return Object.entries(messageTemplateLookup).reduce(
		(base, [stageName, messageTemplates]) => ({
			...base,
			[stageName]: createFrontendPlacementMessageRunner({
				messageTemplates,
				stageName,
				placementId: config.placementId,
			}),
		}),
		{},
	) as T;
};

/*-- Backend --*/

const createBackend =
	<T extends AnyBackendMessageStages = DefaultBackendMessageStages>(
		placementConfig: PlacementConfig,
	) =>
	(placementBackendParams?: PlacementBackend<T>): PlacementMessageBackendFactory<T> => ({
		createMessageBackend: (stages) => (messageBackendConfig) => {
			const stagesWithDefaults = applyDefaultMessageBackendStages(stages);

			return [messageBackendConfig.messageTemplateId, stagesWithDefaults];
		},

		createPlacementBackend: (placementBackendConfig) => (runtime) => {
			const placementBackendStages: AnyPlacementMessageStagesConfig =
				placementBackendParams?.stages ?? defaultBackendPlacementStages;

			const placementId = placementConfig.placementId;

			const placementStages = createBackendPlacementRunners(placementBackendStages);

			const messagesStages = createBackendPlacementMessageTemplateRouter({
				...placementConfig,
				...placementBackendConfig,
			});

			const middleware = placementBackendParams?.customMiddleware ?? defaultPlacementMiddleware;

			const statelessMiddleware = middleware(placementConfig);

			const statelessMessagesRuntime = runtime({
				messagesStages,
				placementStages,
				placementId,
			});

			const koaMiddleware: PlacementRestMiddleware = async (ctx, next) => {
				const koa: PlacementKoaContext = {
					ctx,
					next,
				};

				const request = ctx.state.requestContext.location.tag(placementConfig);

				const messagesRuntime: MessagesRuntime = async (config) => {
					const configuredRequest = (config?.request ?? request).location.tag({
						placementLevel: 'Runtime',
					});

					if (!request) {
						throw new PlacementSystemError({
							message: 'request context not available at runtime call',
						});
					}

					const contexts = { koa, request: configuredRequest };

					const combinedParams: RuntimeParams = {
						...placementBackendParams?.params,
						...config?.params,
					};

					try {
						return placementRuntimeTimedStage(statelessMessagesRuntime)(contexts)(combinedParams);
					} catch (error) {
						throw handlePlacementRuntimeError(contexts.request)(error);
					}
				};

				const postOffice: PlacementPostOfficeContext = {
					messagesRuntime,
				};

				try {
					const middlewareRequest = request.location.tag({
						placementLevel: 'Middleware',
					});

					await statelessMiddleware({
						koa,
						postOffice,
						request: middlewareRequest,
						placement: placementConfig,
					});
				} catch (error) {
					throw handlePlacementMiddlewareError(request)(error);
				}
			};

			return {
				middleware: koaMiddleware,
				config: placementConfig,
			};
		},
	});

const applyDefaultMessageBackendStages = <T extends AnyValidatedBackendMessageStages>(
	stages: () => T,
): T => {
	const validatedDefaultStages: AnyValidatedBackendMessageStages = {};

	Object.entries(defaultBackendPlacementMessageStages).forEach(([stageName, stage]) => {
		validatedDefaultStages[stageName] = {
			validate: noValidation,
			stage,
		};
	});

	return { ...validatedDefaultStages, ...stages() } as T;
};

const createBackendPlacementRunners = <T extends AnyPlacementMessageStagesConfig>(config: T): T => {
	return ((placementMessageStages) => {
		const placementStages = config(placementMessageStages);

		const wrappedPlacementStages: Partial<AnyBackendPlacementStages> = {};

		Object.entries(placementStages).forEach(([stageName, stage]) => {
			if (!stage) {
				wrappedPlacementStages[stageName] = undefined;
			}

			wrappedPlacementStages[stageName] = createBackendPlacementRunner({ stageName, stage });
		});

		return wrappedPlacementStages;
	}) as T;
};

const createBackendPlacementRunner =
	({ stageName, stage }: { stageName: string; stage: AnyBackendPlacementStage }) =>
	async (params: AnyBackendPlacementStageInput) => {
		const request = params.request.location.tag({
			placementLevel: 'Placement',
			stageName,
		});

		if (!stage) {
			return params.data;
		}

		try {
			return placementTimedStage(stage)({ request, data: params.data });
		} catch (error) {
			throw handlePlacementError(request)(error);
		}
	};

const createBackendPlacementMessageTemplateRouter = <T extends AnyBackendMessageStages>(
	config: CreateBackendPlacementMessageExecuteConfig<T>,
): Record<string, T> => {
	const result: Record<string, AnyBackendMessageStages> = {};

	config.messageTemplates.forEach(([messageTemplateId, stages]) =>
		Object.entries(stages).forEach(
			([stageName, stage]: [string, AnyValidatedBackendMessageStage]) => {
				if (!result[messageTemplateId]) result[messageTemplateId] = {};

				// We can safely use a non-null assertion here as we validate the existance of
				// result[messageTemplateId] above
				result[messageTemplateId]![stageName] = createBackendPlacementMessageRunner({
					stage,
					stageName,
					placementId: config.placementId,
				});
			},
		),
	);

	return result as Record<string, T>;
};

const createBackendPlacementMessageRunner: CreateBackendPlacementMessageRunner =
	(config) => async (input) => {
		const { message } = input;

		const request = input.request.location.tag({
			placementLevel: 'PlacementMessage',
			messageTemplateId: message?.messageTemplateId,
			messageInstanceId: message?.messageInstanceId,
			triggerId: message?.triggerId,
			name: config?.stageName,
		});

		try {
			const { validate, stage } = config.stage;

			validate(input.message.context); // Throws if fails validation

			const context = await placementMessageTimedStage(stage)({ request, message });

			return {
				...message,
				context,
			};
		} catch (error) {
			throw handlePlacementMessageError(request)(error);
		}
	};

export const createMessageTemplateByStageLookup = <T extends AnyValidatedStages>(config: {
	messageTemplates: Array<PlacementMessage<T>>;
}): Record<string, T> => {
	const result: Record<string, AnyValidatedStages> = {};

	config.messageTemplates.forEach(([messageTemplateId, stage]) => {
		Object.entries(stage).forEach(([stageName, stageExecutable]) => {
			if (!result[stageName]) result[stageName] = {};

			// We can safely use a non-null assertion here as we validate the existance of
			// result[stageName] above
			result[stageName]![messageTemplateId] = stageExecutable;
		});
	});

	return result as Record<string, T>;
};
